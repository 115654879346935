import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { UserService } from '@thg-procure-team/procure-common-ui/business/user';

@Injectable({
  providedIn: 'root',
})
export class OnBehalfOfFlowGuard implements CanLoad {
  constructor(private readonly userService: UserService, private readonly router: Router) {}

  public canLoad(): boolean {
    const userHasSupplierSites = !!this.userService.getUserSnapshot().supplierSites?.length;

    if (!userHasSupplierSites) {
      this.router.navigate(['/404']);
    }
    return userHasSupplierSites;
  }
}
