import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import dayjs from 'dayjs';
import { UserService } from '@thg-procure-team/procure-common-ui/business/user';
import { ThgcTableStateService } from '@thg-procure-team/procure-common-ui/platform/table';
import { AutoUnsubscribable } from '@thg-procure-team/procure-common-ui/platform/domain';
import { ThgcMaintenanceService } from '@thg-procure-team/procure-common-ui/platform/maintenance';

@Component({
  selector: 'thg-root',
  template: `
    <thgc-maintenance *ngIf="isMaintenance$ | async; else app"></thgc-maintenance>

    <ng-template #app>
      <thgc-toast></thgc-toast>
      <thgc-router-resolve-progressbar></thgc-router-resolve-progressbar>
      <thg-layout-home *ngIf="canDisplay$ | async"></thg-layout-home>
    </ng-template>
  `,
})
export class AppComponent extends AutoUnsubscribable implements OnInit {
  public canDisplay$: Observable<boolean>;
  public isMaintenance$: Observable<boolean>;

  constructor(
    private readonly thgcMaintenanceService: ThgcMaintenanceService,
    private readonly userService: UserService,
    private readonly thgcTableStateService: ThgcTableStateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.thgcTableStateService.setState('shared', {
      purchaseOrders: {
        dateFrom: dayjs()
          .subtract(2, 'month')
          .date(1)
          .toDate(),
        dateTo: new Date(),
      },
      deliveries: {
        dateFrom: dayjs()
          .subtract(2, 'month')
          .date(1)
          .toDate(),
        dateTo: dayjs()
          .add(1, 'month')
          .toDate(),
      },
      invoices: {
        dateFrom: dayjs()
          .subtract(3, 'month')
          .toDate(),
        dateTo: new Date(),
      },
    });
    this.isMaintenance$ = this.thgcMaintenanceService.isMaintenance$;
    this.canDisplay$ = this.userService.user$.pipe(map((user) => !!user));
  }
}
