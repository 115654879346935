import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  RequisitionWorkflowDto,
  RequisitionWorkflowsApiService as BaseRequisitionWorkflowsApiService,
} from '@thg-procure-team/procure-common-ui/business/requisition-workflows';
import { Status } from '@thg-procure-team/procure-common-ui';

@Injectable({
  providedIn: 'root',
})
export class RequisitionWorkflowsApiService {
  constructor(private readonly baseRequisitionWorkflowsApiService: BaseRequisitionWorkflowsApiService) {}

  public getList(): Observable<RequisitionWorkflowDto[]> {
    return this.baseRequisitionWorkflowsApiService.getList({ status: Status.ACTIVE });
  }
}
