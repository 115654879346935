import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { UserService } from '@thg-procure-team/procure-common-ui/business/user';

@Injectable({
  providedIn: 'root',
})
export class ReplenishmentRequestsGuard implements CanLoad {
  constructor(private readonly userService: UserService) {}

  public canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.userService.getUserSnapshot().hasSelfReplenishmentAccess;
  }
}
