import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionsGuard } from '@thg-procure-team/procure-common-ui/platform/security';

import { LayoutNotFoundComponent } from '@procure-supplier/core/layouts/layout-not-found/layout-not-found.component';
import { PermissionLevel } from '@procure-supplier/core/user/domain';
import { OnBehalfOfFlowGuard } from '@procure-supplier/feature/on-befalf-of-flow/guards/on-behalf-of-flow.guard';
import { ReplenishmentRequestsGuard } from '@procure-supplier/feature/replenishment-requests/guards/replenishment-requests.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./feature/dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: { permissions: { 'Supplier Dashboard': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders',
    loadChildren: () =>
      import('./feature/purchase-orders/purchase-orders.module').then((m) => m.PurchaseOrdersModule),
    data: { permissions: { 'Supplier Purchase Order List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders/:purchaseOrderId/asns/:asnId',
    loadChildren: () => import('./feature/asn-details/asn-details.module').then((m) => m.AsnDetailsModule),
    data: { permissions: { 'Supplier Purchase Order List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders/asns-upload',
    loadChildren: () =>
      import('./feature/asns-csv-upload/asns-csv-upload.module').then((m) => m.AsnsCsvUploadModule),
    data: { permissions: { 'Supplier Purchase Order List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders/dates-upload',
    loadChildren: () =>
      import('./feature/dates-csv-upload/dates-csv-upload.module').then((m) => m.DatesCsvUploadModule),
    data: { permissions: { 'Supplier Purchase Order List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders/:id/seasonal-upload',
    loadChildren: () =>
      import('./feature/purchase-order-upload-dates/purchase-order-upload-dates.module').then(
        (m) => m.PurchaseOrderUploadDatesModule,
      ),
    data: { permissions: { 'Supplier Purchase Order List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders/:id',
    loadChildren: () =>
      import('./feature/purchase-order-details/purchase-order-details.module').then(
        (m) => m.PurchaseOrderDetailsModule,
      ),
    data: { permissions: { 'Supplier Purchase Order List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders/:id/upload-quantities',
    loadChildren: () =>
      import('./feature/quantities-csv-upload/quantities-csv-upload.module').then(
        (m) => m.QuantitiesCsvUploadModule,
      ),
    data: { permissions: { 'Supplier Purchase Order List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/purchase-orders/:id/upload/:uploadType',
    loadChildren: () => import('./feature/csv-upload/csv-upload.module').then((m) => m.CsvUploadModule),
    data: { permissions: { 'Supplier Purchase Order List': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'deliveries',
    loadChildren: () => import('./feature/deliveries/deliveries.module').then((m) => m.DeliveriesModule),
    data: { permissions: { 'Supplier Deliveries': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'deliveries/:id',
    loadChildren: () => import('./feature/delivery-asns/delivery-asns.module').then((m) => m.DeliveryAsnsModule),
    data: { permissions: { 'Supplier Deliveries': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'deliveries/:deliveryBookingId/asns/:asnId',
    loadChildren: () => import('./feature/asn-details/asn-details.module').then((m) => m.AsnDetailsModule),
    data: { permissions: { 'Supplier Deliveries': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/invoices',
    loadChildren: () => import('./feature/invoices/invoices.module').then((m) => m.InvoicesModule),
    data: { permissions: { 'Supplier Invoices list': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, OnBehalfOfFlowGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/invoices-upload',
    loadChildren: () =>
      import('./feature/invoice-upload/invoice-upload.module').then((m) => m.InvoiceUploadModule),
    data: { permissions: { 'Supplier Invoices list': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, OnBehalfOfFlowGuard],
  },
  {
    path: 'requisition-workflows/:workflowId/invoices/:id',
    loadChildren: () =>
      import('./feature/invoice-details/invoice-details.module').then((m) => m.InvoiceDetailsModule),
    data: { permissions: { 'Supplier Invoices list': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard, OnBehalfOfFlowGuard],
  },
  {
    path: 'replenishment-requests/upload',
    loadChildren: () =>
      import('./feature/replenishment-requests-csv-upload/replenishment-requests-csv-upload.module').then(
        (m) => m.ReplenishmentRequestsCsvUploadModule,
      ),
    data: { permissions: { 'Replenishment Requests': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, ReplenishmentRequestsGuard],
  },
  {
    path: 'replenishment-requests/:id/upload/asn',
    loadChildren: () => import('./feature/csv-upload/csv-upload.module').then((m) => m.CsvUploadModule),
    canLoad: [ReplenishmentRequestsGuard],
  },
  {
    path: 'replenishment-requests/new',
    loadChildren: () =>
      import('./feature/replenishment-requests-create/replenishment-requests-create.module').then(
        (m) => m.ReplenishmentRequestsCreateModule,
      ),
    data: { permissions: { 'Replenishment Requests': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, ReplenishmentRequestsGuard],
  },
  {
    path: 'replenishment-requests/:id',
    loadChildren: () =>
      import('./feature/replenishment-requests-details/replenishment-requests-details.module').then(
        (m) => m.ReplenishmentRequestsDetailsModule,
      ),
    data: { permissions: { 'Replenishment Requests': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, ReplenishmentRequestsGuard],
  },
  {
    path: 'replenishment-requests',
    loadChildren: () =>
      import('./feature/replenishment-requests/replenishment-requests.module').then(
        (m) => m.ReplenishmentRequestsModule,
      ),
    data: { permissions: { 'Replenishment Requests': PermissionLevel.FULL_CREATED_BY_ME } },
    canLoad: [PermissionsGuard, ReplenishmentRequestsGuard],
  },
  { path: '404', component: LayoutNotFoundComponent },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
