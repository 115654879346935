import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@thg-procure-team/procure-common-ui/business/user';
import { ThgcLocalizationService } from '@thg-procure-team/procure-common-ui/platform/localization';
import { SubSink } from '@thg-procure-team/procure-common-ui/platform/utils';

@Component({
  selector: 'thg-layouts-not-found',
  templateUrl: './layout-not-found.component.html',
  styleUrls: ['./layout-not-found.component.scss'],
})
export class LayoutNotFoundComponent implements OnInit, OnDestroy {
  public notFoundTitle: string;
  public notFoundMessage: string;
  public homePageLabel: string;
  private readonly subSink: SubSink = new SubSink();

  constructor(
    private readonly userService: UserService,
    private readonly thgcLocalizationService: ThgcLocalizationService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.subSink.sink = this.thgcLocalizationService.localeChanged$.subscribe(() => {
      this.notFoundTitle = this.thgcLocalizationService.translate('shared.notFoundTitle');
      this.notFoundMessage = this.thgcLocalizationService.translate('shared.notFoundMessage');
      this.homePageLabel = this.thgcLocalizationService.translate('shared.homeLinkLabel');
    });
  }

  public ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  public onGoToHomeClick(): void {
    this.router.navigate(['/dashboard']);
  }
}
