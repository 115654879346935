import { Observable } from 'rxjs';
import { filter, skip, startWith } from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {
  AnnouncementEntity,
  NavigationMenuItem,
  RightSidebarContentType,
  ThemeMenuItem,
} from '@thg-procure-team/procure-common-ui/platform/layout';
import { ThgcLocalizationService } from '@thg-procure-team/procure-common-ui/platform/localization';
import { UserEntity, UserService } from '@thg-procure-team/procure-common-ui/business/user';
import { AutoUnsubscribable } from '@thg-procure-team/procure-common-ui/platform/domain';
import { ColorType } from '@thg-procure-team/procure-common-ui/platform/theme';
import { AnnouncementInboxService } from '@thg-procure-team/procure-common-ui/business/announcement-inbox';
import { ThgcConfigService } from '@thg-procure-team/procure-common-ui/platform/config';
import { isIndirectWorkflow } from '@thg-procure-team/procure-common-ui/business/requisition-workflows';

import { NavigationStateService } from '@procure-supplier/core/store/navigation-state.service';
import { PermissionLevel } from '@procure-supplier/core/user/domain';

@Component({
  selector: 'thg-layout-home',
  templateUrl: './layout-home.component.html',
  styleUrls: ['./layout-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutHomeComponent extends AutoUnsubscribable implements OnInit {
  public isLeftSidebarOpen: boolean = false;
  public isRightSidebarOpen: boolean = false;
  public rightSidebarContentType: RightSidebarContentType;
  public canDisplayApplication: boolean;
  public navigationMenu: NavigationMenuItem[] = [];
  public themeMenu: ThemeMenuItem[] = [];
  public user$: Observable<UserEntity>;
  public announcements: Observable<AnnouncementEntity[]>;
  public unreadAnnouncementCount: Observable<number>;
  private currentUserHasSupplierSites: boolean;

  constructor(
    private readonly thgcLocalizationService: ThgcLocalizationService,
    private readonly navigationStateService: NavigationStateService,
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly thgcConfigService: ThgcConfigService,
    private readonly router: Router,
    private readonly announcementInboxService: AnnouncementInboxService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.user$ = this.userService.user$;
    this.announcements = this.announcementInboxService.announcements$;
    this.unreadAnnouncementCount = this.announcementInboxService.unreadAnnouncementCount;
    this.currentUserHasSupplierSites = !!this.userService.getUserSnapshot().supplierSites?.length;
    this.setupMenuItems();
  }

  public onLeftSidebarToggle(isLeftSidebarOpen: boolean): void {
    this.isLeftSidebarOpen = isLeftSidebarOpen;
  }

  public onRightSidebarToggle(isRightSidebarOpen: boolean, type?: RightSidebarContentType): void {
    this.isRightSidebarOpen = isRightSidebarOpen;
    this.rightSidebarContentType = type;
  }

  public onLogout(): void {
    this.userService.logout();
  }

  public onLocaleChange(locale: string): void {
    this.userService.setLanguage(locale);
  }

  public onThemeChange(theme: string): void {
    this.userService.setTheme(theme);
  }

  private setupMenuItems(): void {
    this.subSink.sink = this.thgcLocalizationService.localeChanged$.subscribe(() => {
      this.themeMenu = [
        { label: this.thgcLocalizationService.translate('appSettings.darkThemeLabel'), key: 'dark' },
        { label: this.thgcLocalizationService.translate('appSettings.lightThemeLabel'), key: 'light' },
      ];

      this.navigationMenu = [
        {
          label: this.thgcLocalizationService.translate('navigation.dashBoardTitle'),
          isTitle: true,
        },
        {
          label: this.thgcLocalizationService.translate('navigation.dashBoard'),
          icon: 'dripicons-bell',
          link: '/dashboard',
        },
        {
          label: this.thgcLocalizationService.translate('navigation.mainTitle'),
          isTitle: true,
        },
        {
          label: this.thgcLocalizationService.translate('navigation.purchaseOrdersLabel'),
          icon: 'dripicons-duplicate',
          link: '/purchase-orders',
        },
        {
          label: this.thgcLocalizationService.translate('navigation.replenishmentRequestsLabel'),
          icon: 'dripicons-exit',
          link: '/replenishment-requests',
          isHidden: !this.userService.getUserSnapshot().hasSelfReplenishmentAccess,
        },
        {
          label: this.thgcLocalizationService.translate('navigation.deliveriesLabel'),
          icon: 'uil uil-truck',
          link: '/deliveries',
        },
      ];

      if (this.currentUserHasSupplierSites) {
        this.navigationMenu = this.navigationMenu.concat([
          {
            label: this.thgcLocalizationService.translate('navigation.invoicesLabel'),
            icon: 'dripicons-document',
            link: '/invoices',
          },
        ]);
      }

      this.watchRouterNavigation();
      this.loadRequisitionWorkflows();
      this.changeDetectorRef.markForCheck();
    });
  }

  private watchRouterNavigation(): void {
    this.subSink.sink = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        startWith([]),
      )
      .subscribe(() => this.updateDashboardBadge());
  }

  private updateDashboardBadge(): void {
    const dashboardIndex = 1;
    this.navigationStateService.loadDashboardNotifications();
    this.subSink.sink = this.navigationStateService.pendingActionsCount$
      .pipe()
      .subscribe((pendingActionsCount) => {
        const dashboardNavigationItem = this.navigationMenu[dashboardIndex];
        if (pendingActionsCount && pendingActionsCount > 0) {
          const badgeText = pendingActionsCount > 99 ? '99+' : pendingActionsCount.toString();
          dashboardNavigationItem.badge = { colorType: ColorType.DANGER, text: badgeText };
        } else {
          dashboardNavigationItem.badge = null;
        }

        this.navigationMenu = [...this.navigationMenu];
      });
  }

  private findMenuItemIndexByLabel(menuItemLabel: string): number {
    return this.navigationMenu.findIndex((menuItem) => menuItem.label && menuItem.label === menuItemLabel);
  }

  private loadRequisitionWorkflows(): void {
    this.navigationStateService.loadRequisitionWorkflows();
    this.subSink.sink = this.navigationStateService.requisitionWorkflows$
      .pipe(skip(1))
      .subscribe((workflowList) => {
        const purchaseOrderMenuIndex = this.findMenuItemIndexByLabel(
          this.thgcLocalizationService.translate('navigation.purchaseOrdersLabel'),
        );
        const invoicesMenuIndex = this.findMenuItemIndexByLabel(
          this.thgcLocalizationService.translate('navigation.invoicesLabel'),
        );
        this.navigationMenu[purchaseOrderMenuIndex].children = [];
        if (invoicesMenuIndex >= 0) {
          this.navigationMenu[invoicesMenuIndex].children = [];
        }
        workflowList.forEach((workflow) => {
          if (
            this.userService.hasPermissions({
              ['Supplier ' + workflow.name + ' Procurement']: PermissionLevel.FULL_ALL_BUSINESS_UNITS,
            })
          ) {
            this.navigationMenu[purchaseOrderMenuIndex].children.push({
              label: this.thgcLocalizationService.translate('navigation.purchaseOrderWorkflowLabel', [
                workflow.name,
              ]),
              link: `/requisition-workflows/${workflow.id}/purchase-orders`,
            });
            if (this.currentUserHasSupplierSites) {
              this.navigationMenu[invoicesMenuIndex].children.push({
                label: this.thgcLocalizationService.translate('navigation.invoicesWorkflowLabel', [workflow.name]),
                link: `/requisition-workflows/${workflow.id}/invoices`,
              });
            }
          }
        });
        if (invoicesMenuIndex >= 0 && !this.navigationMenu[invoicesMenuIndex].children.length) {
          this.navigationMenu.splice(invoicesMenuIndex, 1);
        }
        if (!this.navigationMenu[purchaseOrderMenuIndex].children.length) {
          this.navigationMenu.splice(purchaseOrderMenuIndex, 1);
        }
        this.navigationMenu = [...this.navigationMenu];
        this.canDisplayApplication = true;
        this.changeDetectorRef.detectChanges();
      });
  }

  public toggleAnnouncementRead(announcement: AnnouncementEntity): void {
    this.announcementInboxService.toggleAnnouncementRead(announcement);
  }
}
